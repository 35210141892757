
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import ProductReservationResource from '@/resources/ProductReservationResource';
import Form from '@/components/ProductReservation/Form.vue';
import useForm from '@/composables/use-form';

export default defineComponent({
    components: { Form },

    setup() {
        const router = useRouter();
        const reservation = reactive({
            user_id: null,
            reserved_from: null,
            reserved_to: null,
        });
        const form = useForm(reservation, ProductReservationResource);
        const { isSaving, save  } = form;

        function submit() {
            save().then(() => {
                router.go(-1);
            });
        }

        return {
            form,
            isSaving,
            submit,
        };
    },
});
